27th<template>
  <div class="Conocimiento">
    <section class="pt96 pb0">
      <div class="container">
        <div class="row mb24 mb-xs-24">
          <div class="col-sm-12 text-center">
            <h1 class="fade-1-4 uppercase">Centro de conocimiento</h1>
            <p class="mb0">
              Nuestra metodología terapéutica se basa en manifestar que, ante un síntoma fisiológico existe una emoción vivida bajo un shock, el cual debe ser gestionado conscientemente para su reparación. </p>
          </div>
        </div>
      </div>
    </section>
    <section class="pt-xs-64 pb0">
      <div class="container">
        <div
          v-for="(cmt,idx) in conocimiento"
          :key="idx"
          class="mt48"
        >
          <div class="row mb48 mt48 mb-xs-24">
            <div class="col-sm-12 text-center">
              <h4 class="uppercase mb16"><br>{{cmt.titulo_pub}}</h4>
              <span
                class="fade-1-4"
                style="text-transform:uppercase"
              >{{$Datetime.fromSQL(cmt.fecha_pub).setLocale('es-Mx').toFormat('DD')}} </span>
            </div>
          </div>
          <div
            class="row mb48 mb-xs-24"
            v-if="cmt.imagen_pub"
          >
            <div class="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1">
              <img
                :alt="cmt.titulo_pub"
                :src="$baseUrl+cmt.imagen_pub"
              />
            </div>
          </div>
          <div class="row mb40 mb-xs-24">
            <div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
              <p v-text="cmt.descripcion_pub" />
            </div>
          </div>
          <div class="row">
            <div class="
              col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1
              text-center
            ">
              <router-link
                class="btn mb64 mb-xs-24"
                :to="'/articulo/'+cmt.id+'/'+cmt.url_pub"
                v-text="'Leer artículo'"
              />
              <hr class="mb0" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="row">
      <br /><br /><br /><br />
      <div class="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 text-center">
        <a
          class="btn btn-filled mb64 mb-xs-24"
          @click="counter++"
        >Cargar más</a>
      </div>
    </div>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  mixins: [forms],
  data() {
    return {
      conocimiento: [],
      counter: 1,
    };
  },
  methods: {
    async _getConocimientos() {
      if (this.counter < 1) {
        this.counter = 1;
      }
      this.cargando = true;
      try {
        const { status, body } = await this.$http.get(
          "conocimientos?page=" + this.counter
        );
        if (status === 200 && "conocimiento" in body) {
          if (this.conocimiento.length >= 1) {
            const newArr = this.conocimiento.concat(body.conocimiento);
            this.conocimiento = newArr;
          } else {
            this.conocimiento = body.conocimiento;
          }
        }
        this.cargando = false;
      } catch (err) {
        this.cargando = false;
      }
    },
  },
  watch: {
    counter: {
      deep: true,
      immediate: true,
      handler: "_getConocimientos",
    },
  },
};
</script>